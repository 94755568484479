import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBookMedical,
  faDownload,
  faCog,
  faMicroscope,
  faRedo,
  faSearch,
  faSync,
  faLock,
  faTasks,
  faSlidersH,
  faExternalLinkAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faCheck,
  faCheckDouble,
  faCheckCircle,
  faChartBar,
  faComment,
  faGraduationCap,
  faPlus,
  faPlusCircle,
  faMinusCircle,
  faArrowLeft,
  faSpinner,
  faQuestionCircle,
  faBug,
  faTable,
  faEdit,
  faAngleRight,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faBan,
  faFlask,
  faComments,
  faEye,
  faEyeSlash,
  faTimes,
  faUser,
  faReply,
  faTrashAlt,
  faRecycle,
  faSave,
  faHighlighter,
  faUndo,
  faSearchPlus,
  faSortUp,
  faSortDown,
  faCaretUp,
  faCaretDown,
  faPencilAlt,
  faFileImport,
  faFileUpload,
  faFileExport,
} from '@fortawesome/free-solid-svg-icons';

export default function registerIcons() {
  // Add icons to the library
  library.add(faMicroscope);
  library.add(faBookMedical);
  library.add(faDownload);
  library.add(faSearch);
  library.add(faCog);
  library.add(faSync);
  library.add(faRedo);
  library.add(faLock);
  library.add(faTasks);
  library.add(faSlidersH);
  library.add(faExternalLinkAlt);
  library.add(faExclamationCircle);
  library.add(faExclamationTriangle);
  library.add(faInfoCircle);
  library.add(faCheck);
  library.add(faCheckDouble);
  library.add(faCheckCircle);
  library.add(faChartBar);
  library.add(faGraduationCap);
  library.add(faPlus);
  library.add(faPlusCircle);
  library.add(faMinusCircle);
  library.add(faArrowLeft);
  library.add(faSpinner);
  library.add(faQuestionCircle);
  library.add(faBug);
  library.add(faTable);
  library.add(faEdit);
  library.add(faAngleRight);
  library.add(faAngleDoubleRight);
  library.add(faAngleLeft);
  library.add(faAngleDoubleLeft);
  library.add(faBan);
  library.add(faFlask);
  library.add(faComments);
  library.add(faEye);
  library.add(faEyeSlash);
  library.add(faTimes);
  library.add(faUser);
  library.add(faReply);
  library.add(faTrashAlt);
  library.add(faRecycle);
  library.add(faSave);
  library.add(faHighlighter);
  library.add(faUndo);
  library.add(faComment);
  library.add(faSearchPlus);
  library.add(faSortUp);
  library.add(faSortDown);
  library.add(faCaretUp);
  library.add(faCaretDown);
  library.add(faPencilAlt);
  library.add(faFileImport);
  library.add(faFileUpload);
  library.add(faFileExport);
}
